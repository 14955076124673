<template>
    <div class="flex flex-column flex-item white-bg">
        <div class="flex flex-center" style="padding: 15px;width:500px">
            选择大类：
            <el-select v-model="treatBig" filterable reserve-keyword @change="init"
                       placeholder="请输入大类关键词" :loading="dataLoading">
                <el-option v-for="item in option" :key="item.id" :label="item.treat_big"  :value="item.treat_big"></el-option>
            </el-select>
        </div>
        <div v-loading="loading" id="through-category" v-if="loaded" class="overview-canvas canvas-connect flex-item" style="height: 600px;"></div>
    </div>
</template>

<script>
    import service from '@/util/api';
    // region 散射图公共部分
    let indexId = 1;
    const connectKey = '*#06#';
    import help from '@/util/help'

    // 获取真实的名字
    function getRealName(name) {
        if (name.indexOf(connectKey) !== -1) {
            return name.split(connectKey)[0]
        }
        return name
    }

    function formatData(item, color) {
        item.symbolSize = 50

        item.itemStyle = {
            color:color,
        };
        item.uuid = `canvas${indexId++}`
        return item
    }
    function formatDrug(data, qtyKey = 'test') {
        return data.map(item => {
            return formatData({
                category: 0,
                name: `${item.com_name}${connectKey}${indexId}`,
                qty: item[qtyKey],
            }, '#FFA12F')
        })
    }
    function formatOther(data, key, qtyKey = 'test') {
        return data.map(item => {
            return formatData({
                category: 1,
                name: `${item[key]}${connectKey}${indexId}`,
                qty: item[qtyKey],
            }, '#5171DF')
        })
    }
    function formatSecond(data, qtyKey = 'test') {
        return data.map(item => {
            return formatData({
                category: 2,
                name: `${item.com_name}${connectKey}${indexId}`,
                qty: item[qtyKey],
            }, '#10a050')
        })
    }
    function getCanvasLine(source, targets) {
        const res = []
        targets.forEach(item => {
            res.push({
                lineStyle: {color: "#df001a"},
                source: source,
                target: item.name,
                name: '',
            })
        })
        return res
    }
    export default {
        data() {
            return {
                treatBig: '',
                loaded: false,
                loading: false,

                dataLoading: false,
                option: [],
            }
        },
        mounted() {
            // 获取所有大类
            service.get('aim_drugs_sale_year_treat_big/drugs-sale-year-treat-big-select')
                .then(res => {
                    if (res.data) {
                        this.option = res.data;
                    } else if (res.code == '405') {
                        this.$store.commit('error', res.message)
                    }
                })

        },
        methods: {
            init() {
                this.loaded = false
                this.loading = true
                this.$nextTick(() => {
                    this.loaded = true

                    this.$nextTick(() => {
                        const self = this
                        // 设置一个变量，记录那些节点点击过
                        const recordObj = {};

                        let myChart = null;
                        let canvasData = {
                            nodes: [],
                            links: [],
                        };

                        function commonRender() {
                            canvasData.nodes = formatDrug([{com_name: self.treatBig}]);

                            service.get('instruction_book_index/index-treat-small-from-treat-big', {
                                treat_big: self.treatBig,
                            })
                                .then(res => {
                                    if (res.code === 200 && res.data) {
                                        const items = formatOther(res.data, 'treat_small', 'qty');
                                        canvasData.nodes = canvasData.nodes.concat(items)
                                        canvasData.links = canvasData.links.concat(getCanvasLine(canvasData.nodes[0].name, items))
                                        myChart.setOption({
                                            series: help.getCanvasSeries(canvasData)
                                        })

                                        myChart.on('click', function (obj) {
                                            const key = obj.data.uuid;
                                            if (!recordObj[key] && obj.data.category === 1) {
                                                recordObj[key] = true;
                                                service.get('instruction_book_index/index-com-name-from-treat-small', {
                                                    treat_small: getRealName(obj.data.name),
                                                })
                                                    .then(res => {
                                                        if (res.code === 200 && res.data && res.data.length > 0) {
                                                            const list = res.data;

                                                            // 屏蔽掉自己这个
                                                            for (let i = 0; i < list.length; i++) {
                                                                if (list[i].com_name === self.selectCheckName) {
                                                                    list.splice(i, 1)
                                                                    break
                                                                }
                                                            }


                                                            const items = formatSecond(list, 'sale_amount');
                                                            canvasData.nodes = canvasData.nodes.concat(items)
                                                            canvasData.links = canvasData.links.concat(getCanvasLine(obj.data.name, items))

                                                            myChart.setOption({
                                                                series: help.getCanvasSeries(canvasData)
                                                            })
                                                        }

                                                    })
                                            }

                                        })

                                    }
                                })
                        }

                        window.comTypeRender = () => {
                            canvasData.links = [];
                            canvasData.node = [];
                            commonRender();
                        };

                        setTimeout(() => {
                            myChart = window.echarts.init(document.getElementById('through-category'));
                            commonRender();
                        }, 300);
                    })
                })

            }
        }
    }
</script>